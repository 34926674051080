import React from "react";
import {ButtonCircle} from "factor";

import styles from "./Colorimeter.module.scss";

const Colorimeter = (props) => {
	const {
		className,
		label,
		color,
	} = props;

	return (
		<div className={`${styles.container} ${className || ""}`}>
			<div className={styles.label}>
				{label}
			</div>
			<div className="d-flex align-items-center">
				<div className={styles.control} style={{backgroundColor: color}}></div>
				<ButtonCircle outline className={`${styles.edit} _size-20`} iconName="Edit"/>
			</div>
		</div>
	);
};

export default Colorimeter;