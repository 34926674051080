import React, {Component} from 'react';

import styles from './Textarea.module.scss';

export default class Textarea extends Component {
	constructor() {
		super();
	}

	render() {
		const {
			className,
			label,
			placeholder,
			value,
			onChange,
		} = this.props;

		return (
			<div className={`${styles.container} ${className || ""}`}>
				<label htmlFor={label}>
					{label}
				</label>
				<textarea
					value={value}
					placeholder={placeholder}
					onChange={({target}) => onChange(target.value)}
					id={label}
				/>
			</div>
		);
	}
}