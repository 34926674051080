import React, {Component} from "react";
import {
	TextField,
	Select,
} from "factor";

import File from "components/File/File";

import styles from "./ImageSidebar.module.scss";

import image1 from "img/example/html/html-1.jpg";
import image2 from "img/example/html/html-2.jpg";

class ImageSidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			clickUrl: "",
			pixelUrl: "",
		}
	}

	render() {
		const {
			clickUrl,
			pixelUrl,
		} = this.state;

		return (
			<div className={styles.container}>
				<div className={`row ${styles.row}`}>
					<div className="col-6">
						<File
							label="Add New"
							btnTitle="Browse"
						/>
					</div>
				</div>
				<div className={styles.list}>
					{[image1, image2].map((i, k) => (
						<div className={styles.item} key={k}>
							<div className={styles.image}>
								<img src={i} alt=""/>
							</div>
							<div className={styles.itemWrapper}>
								<h4 className={styles.itemTitle}>
									Banner{k+1}.jpg
								</h4>
								<div className={styles.row}>
									<TextField
										name="click-url"
										label="* Click URL"
										onChange={( v ) => this.setState({
											clickUrl: v,
										})}
										value={clickUrl}
									/>
								</div>
								<TextField
									name="pixel-url"
									label="Pixel URL"
									onChange={( v ) => this.setState({
										pixelUrl: v,
									})}
									value={pixelUrl}
								/>
							</div>
						</div>
					))}
				</div>
			</div>
		);
	}
}


export default ImageSidebar;