import React, {Component} from "react";
import {
	Button,
	TextField,
} from "factor";
import {connect} from "react-redux";

import {setDialogVisibility} from "store/actions";

import image from "img/example/html/html-1.jpg";

import styles from "./SidebarHeader.module.scss";

class SidebarHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			creativeName: "",
		}
	}

	render() {
		const {
			dialogOpened,
			setDialogVisibility,
			withPreview = true,
		} = this.props;

		const {
			creativeName,
		} = this.state;

		return (
			<div className={styles.container}>
				{withPreview &&
					<React.Fragment>
						<button
							className={styles.preview}
							onClick={() => setDialogVisibility(!dialogOpened)}
						>
							<img src={image} alt="preview"/>
						</button>
						<Button
							className="btn-square _orange align-self-end"
							onClick={() => setDialogVisibility(!dialogOpened)}
						>
							Preview
						</Button>
					</React.Fragment>
				}
				<TextField
					label="Creative Name"
					value={creativeName}
					onChange={(v) => this.setState({creativeName: v})}
				/>
			</div>
		);
	}
}


export default connect(
	state => ({
		dialogOpened: state.dialogOpened,
	}),
	dispatch => (setDialogVisibility(dispatch))
)(SidebarHeader);