import React, {Component} from "react";

import {ReactComponent as PlayLogo} from "img/play.svg";
import {ReactComponent as PauseLogo} from "img/pause.svg";

import styles from "./Player.module.scss";

class Player extends Component {
	constructor() {
		super();
		this.state = {
			isPlaying: false,
		}
	}

	render() {
		const {
			className,
		} = this.props;

		const {
			isPlaying,
		} = this.state;

		return (
			<div className={`${styles.container} ${className || ""}`}>
				<button className={styles.btn} onClick={() => this.setState({isPlaying: !isPlaying})}>
					{!isPlaying ?
						<PlayLogo/>
						:
						<PauseLogo/>
					}
				</button>
				<div className={styles.time}>
					0:00 / 0:00
				</div>
				<div className={styles.progress}>
					<div className={styles.fill} style={{width: "45%"}}></div>
				</div>
			</div>
		)}
}

export default Player;